import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import './DashboardTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faSortUp, faSortDown, faSignOutAlt, faRedo, faCalendarAlt, faFilter, faUndo, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import ReactCountryFlag from "react-country-flag";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse, isValid, addDays } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const Sidebar = ({ fetchData, filterToday, resetFilters, filterByUrlPattern, logout, toggleTotalClicksTable, showTotalClicks, searchQuery, setSearchQuery }) => (
    <div className="sidebar">
        <div className="sidebar-title">CTX7 0.3</div>
        <div className="search-bar">
            <input
                type="text"
                className="search-input"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button className="search-button">
                <FontAwesomeIcon icon={faSearch} />
            </button>
        </div>
        <button className="sidebar-button" onClick={fetchData} title="Refresh Data">
            <FontAwesomeIcon icon={faSyncAlt} spin={false} /> Refresh
        </button>
        <button className="sidebar-button" onClick={filterToday} title="Toggle Today's Data">
            <FontAwesomeIcon icon={faCalendarAlt} /> Toggle Today
        </button>
        <button className="sidebar-button" onClick={resetFilters} title="Reset All Filters">
            <FontAwesomeIcon icon={faUndo} /> Reset Filters
        </button>
        <button className="sidebar-button" onClick={() => filterByUrlPattern('/14g/')} title="Filter by Social Media">
            <FontAwesomeIcon icon={faFilter} /> Social Media
        </button>
        <button className="sidebar-button" onClick={() => filterByUrlPattern('/14earning/')} title="Filter by Earning">
            <FontAwesomeIcon icon={faFilter} /> Earning
        </button>
        <button className="sidebar-button" onClick={() => filterByUrlPattern('/14afd/')} title="Filter by AFD">
            <FontAwesomeIcon icon={faFilter} /> AFD
        </button>
        <button className="sidebar-button" onClick={toggleTotalClicksTable} title="Toggle Total Clicks">
            <FontAwesomeIcon icon={faRedo} /> {showTotalClicks ? 'Hide Total Clicks' : 'Show Total Clicks'}
        </button>
        <button className="sidebar-button" onClick={logout} title="Logout">
            <FontAwesomeIcon icon={faSignOutAlt} /> Logout
        </button>
    </div>
);

const Modal = ({ isOpen, onClose, url, details }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>{url}</h2>
                    <button className="close-button" onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className="modal-body">
                    <table className="modal-table">
                        <thead>
                            <tr>
                                <th>IP</th>
                                <th>Click ID</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {details.map((detail, index) => (
                                <tr key={index}>
                                    <td>{detail.ip}</td>
                                    <td>{detail.clickid}</td>
                                    <td>{isValid(parseAdjustedDate(detail.dateTime)) ? format(parseAdjustedDate(detail.dateTime), 'Pp') : 'Invalid Date'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const parseAdjustedDate = (dateTime) => {
    let adjustedDateTime = dateTime;
    if (dateTime.includes('24:')) {
        adjustedDateTime = dateTime.replace('24:', '00:');
        const parsedDate = parse(adjustedDateTime, 'M/d/yyyy, HH:mm', new Date());
        return addDays(parsedDate, 1);
    }
    return parse(adjustedDateTime, 'M/d/yyyy, HH:mm', new Date());
};

function Dashboard() {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState('');
    const [sortDirection, setSortDirection] = useState('desc');
    const [sortColumn, setSortColumn] = useState('dateTime');
    const [showToday, setShowToday] = useState(true);
    const [urlFilter, setUrlFilter] = useState('');
    const [countryFilter, setCountryFilter] = useState('');
    const [showTotalClicks, setShowTotalClicks] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({ url: '', details: [] });

    const flagMapping = useMemo(() => ({
        'https://hraccount': 'HR',
        'https://itaccount': 'IT',
        'https://czaccount': 'CZ',
        'https://accountza': 'ZA',
        'https://beaccount': 'BE',
        'https://chaccount': 'CH',
        'https://rsaccount': 'RS',
        'https://esaccount': 'ES',
        'https://slaccount': 'SI',
    }), []);

    const fetchData = useCallback(async () => {
        const token = sessionStorage.getItem('token');
        const sessionId = sessionStorage.getItem('sessionId');

        if (!token || !sessionId) {
            alert('Token and session ID are required');
            return;
        }

        setLoading(true);
        setNotification('');
        try {
            const response = await axios.get('https://globalnastrategiahnadlowanaxxiwiek.com/api/ctx7', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'SessionId': sessionId
                }
            });
            if (Array.isArray(response.data)) {
                const sortedData = response.data.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime));
                const dataWithFlags = sortedData.map(item => {
                    const subdomain = item.url.split('.')[0];
                    return {
                        ...item,
                        flagCode: flagMapping[subdomain] || ''
                    };
                });
                setData(dataWithFlags);
                filterData(dataWithFlags);
                setNotification('Data has been refreshed!');
                setTimeout(() => setNotification(''), 2000);
            } else {
                console.error('Data received is not an array:', response.data);
                setData([]);
                setFilteredData([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert('Failed to fetch data. Check the console for more details.');
            setData([]);
            setFilteredData([]);
        }
        setLoading(false);
    }, [flagMapping]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const toggleSort = (column) => {
        let sortedData;
        if (sortColumn === column) {
            sortedData = [...filteredData].sort((a, b) => {
                if (column === 'dateTime') {
                    return sortDirection === 'desc'
                        ? new Date(a.dateTime) - new Date(b.dateTime)
                        : new Date(b.dateTime) - new Date(a.dateTime);
                } else if (column === 'clickCount') {
                    return sortDirection === 'desc'
                        ? a.clickCount - b.clickCount
                        : b.clickCount - a.clickCount;
                } else if (column === 'flagCode') {
                    return sortDirection === 'desc'
                        ? a.flagCode.localeCompare(b.flagCode)
                        : b.flagCode.localeCompare(a.flagCode);
                } else if (column === 'clickid') {
                    return sortDirection === 'desc'
                        ? a.clickid.localeCompare(b.clickid)
                        : b.clickid.localeCompare(a.clickid);
                } else {
                    return 0;
                }
            });
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            sortedData = [...filteredData].sort((a, b) => {
                if (column === 'dateTime') {
                    return new Date(b.dateTime) - new Date(a.dateTime);
                } else if (column === 'clickCount') {
                    return b.clickCount - a.clickCount;
                } else if (column === 'flagCode') {
                    return b.flagCode.localeCompare(a.flagCode);
                } else if (column === 'clickid') {
                    return b.clickid.localeCompare(a.clickid);
                } else {
                    return 0;
                }
            });
            setSortDirection('desc');
            setSortColumn(column);
        }
        setFilteredData(sortedData);
    };

    const filterData = (data) => {
        let filtered = data;

        const polandTime = 'Europe/Warsaw';
        const localDateString = (date) => {
            const parsedDate = parseAdjustedDate(date);
            if (!isValid(parsedDate)) {
                console.error('Invalid date:', date);
                return '';
            }
            return format(toZonedTime(parsedDate, polandTime), 'yyyy-MM-dd', { timeZone: polandTime });
        };

        if (showToday) {
            const today = format(toZonedTime(new Date(), polandTime), 'yyyy-MM-dd', { timeZone: polandTime });
            filtered = filtered.filter(item => localDateString(item.dateTime) === today);
        }

        if (selectedDate) {
            const selectedDateString = format(toZonedTime(selectedDate, polandTime), 'yyyy-MM-dd', { timeZone: polandTime });
            filtered = filtered.filter(item => localDateString(item.dateTime) === selectedDateString);
        }

        if (urlFilter) {
            filtered = filtered.filter(item => item.url.includes(urlFilter));
        }

        if (countryFilter) {
            filtered = filtered.filter(item => item.flagCode === countryFilter);
        }

        if (searchQuery) {
            filtered = filtered.filter(item => 
                item.url.toLowerCase().includes(searchQuery.toLowerCase()) || 
                item.ip.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.flagCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.clickid.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredData(filtered);
    };

    const filterToday = () => {
        setShowToday(!showToday);
    };

    const filterByUrlPattern = (pattern) => {
        setUrlFilter(pattern);
    };

    const filterByCountry = (countryCode) => {
        setCountryFilter(countryCode);
    };

    const resetFilters = () => {
        setUrlFilter('');
        setCountryFilter('');
        setShowToday(false);
        setSelectedDate(null);
        setSearchQuery('');
    };

    useEffect(() => {
        filterData(data);
    }, [showToday, urlFilter, countryFilter, selectedDate, searchQuery, data]);

    const calculateTotalClicks = () => {
        const urlClickCounts = {};
        const polandTime = 'Europe/Warsaw';
        const localDateString = (date) => {
            const parsedDate = parseAdjustedDate(date);
            if (!isValid(parsedDate)) {
                console.error('Invalid date:', date);
                return '';
            }
            return format(toZonedTime(parsedDate, polandTime), 'yyyy-MM-dd', { timeZone: polandTime });
        };

        filteredData.forEach(item => {
            const date = localDateString(item.dateTime);
            const key = `${item.url}_${date}`;

            if (urlClickCounts[key]) {
                urlClickCounts[key].clicks += item.clickCount;
                urlClickCounts[key].uniqueIps.add(item.ip); // Track unique IPs
            } else {
                urlClickCounts[key] = { url: item.url, clicks: item.clickCount, uniqueIps: new Set([item.ip]), date };
            }
        });

        return Object.values(urlClickCounts).map(({ url, clicks, uniqueIps, date }) => ({
            url,
            clicks,
            uniqueClicks: uniqueIps.size,
            date
        }));
    };

    const toggleTotalClicksTable = () => {
        setShowTotalClicks(!showTotalClicks);
    };

    const logout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('sessionId');
        window.location.href = '/login';
    };

    const totalClicks = useMemo(calculateTotalClicks, [filteredData]);

    const handleUniqueClicksClick = (url, date) => {
        const details = filteredData.filter(item => {
            const itemDate = format(toZonedTime(parseAdjustedDate(item.dateTime), 'Europe/Warsaw'), 'yyyy-MM-dd', { timeZone: 'Europe/Warsaw' });
            return item.url === url && itemDate === date;
        });
        setModalData({ url, details });
        setModalOpen(true);
    };

    return (
        <div className="dashboard-container">
            <Sidebar 
                fetchData={fetchData}
                filterToday={filterToday}
                resetFilters={resetFilters}
                filterByUrlPattern={filterByUrlPattern}
                logout={logout}
                toggleTotalClicksTable={toggleTotalClicksTable}
                showTotalClicks={showTotalClicks}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
            />
            <div className="main-content">
                {notification && (
                    <div className="notification">
                        {notification}
                        <button className="close-button" onClick={() => setNotification('')}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                )}
                <div className="date-filter">
                    <label>Select Date: </label>
                    <div className="react-datepicker__input-container">
                        <DatePicker
                            selected={selectedDate}
                            onChange={date => setSelectedDate(date)}
                            dateFormat="M/d/yyyy"
                            isClearable
                            placeholderText="Select a date"
                            className="custom-datepicker"
                            popperClassName="custom-datepicker-popper"
                        />
                        {selectedDate && (
                            <button className="date-clear-button" onClick={() => setSelectedDate(null)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        )}
                    </div>
                </div>
                {!showTotalClicks ? (
                    <table className="table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>IP</th>
                                <th>URL</th>
                                <th onClick={() => toggleSort('flagCode')} style={{ cursor: 'pointer' }}>
                                    Flag
                                    <FontAwesomeIcon icon={sortDirection === 'asc' && sortColumn === 'flagCode' ? faSortUp : faSortDown} />
                                </th>
                                <th onClick={() => toggleSort('clickid')} style={{ cursor: 'pointer' }}>
                                    Click ID
                                    <FontAwesomeIcon icon={sortDirection === 'asc' && sortColumn === 'clickid' ? faSortUp : faSortDown} />
                                </th>
                                <th onClick={() => toggleSort('clickCount')} style={{ cursor: 'pointer' }}>
                                    Click Count
                                    <FontAwesomeIcon icon={sortDirection === 'asc' && sortColumn === 'clickCount' ? faSortUp : faSortDown} />
                                </th>
                                <th onClick={() => toggleSort('dateTime')} style={{ cursor: 'pointer' }}>
                                    Date Time
                                    <FontAwesomeIcon icon={sortDirection === 'asc' && sortColumn === 'dateTime' ? faSortUp : faSortDown} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.ip}</td>
                                    <td>{item.url}</td>
                                    <td>
                                        {item.flagCode ? (
                                            <div className="flag">
                                                <ReactCountryFlag countryCode={item.flagCode} svg style={{ width: '20px', height: '15px', marginRight: '8px', marginLeft: '16px', cursor: 'pointer' }} onClick={() => filterByCountry(item.flagCode)} />
                                                {item.flagCode}
                                            </div>
                                        ) : 'N/A'}
                                    </td>
                                    <td>{item.clickid}</td>
                                    <td>{item.clickCount}</td>
                                    <td>{isValid(parseAdjustedDate(item.dateTime)) ? format(parseAdjustedDate(item.dateTime), 'Pp') : 'Invalid Date'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <table className="total-clicks-table">
                        <thead>
                            <tr>
                                <th>URL</th>
                                <th>Total Clicks</th>
                                <th>Unique Clicks</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {totalClicks.map(({ url, clicks, uniqueClicks, date }) => (
                                <tr key={`${url}_${date}`}>
                                    <td>{url}</td>
                                    <td>{clicks}</td>
                                    <td 
                                        onClick={() => handleUniqueClicksClick(url, date)} 
                                        style={{ cursor: 'pointer', color: 'var(--accent-color)' }}
                                    >
                                        {uniqueClicks}
                                    </td>
                                    <td>{date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <Modal 
                isOpen={modalOpen} 
                onClose={() => setModalOpen(false)} 
                url={modalData.url} 
                details={modalData.details} 
            />
        </div>
    );
}

export default Dashboard;
